import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "formik";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

function EntregaCodigo(props) {
    const dispatch = useDispatch();
    const { form } = props;
    const { setFieldValue } = form;

    useEffect(() => {
        setFieldValue("entrega_codigo", 0);
        dispatch(AcaoCreators.preview("entrega_codigo", "Não"));
    }, [setFieldValue, dispatch]);

    return (
        <>
            <ErrorMessage component="span" name="entrega_codigo" />
        </>
    );
}

export default EntregaCodigo;
