import styled from "styled-components";

export const Container = styled.div`
  /* background: linear-gradient(90deg, #0097d5 0%, #005d88 100%); */
  height: 80px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e2e3;
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.preto};
  }
  .title-gerencia {
    display: flex;
    align-items: center;
    grid-gap: 30px;
    a {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      font-weight: 700;
      font-size: 11px;
      line-height: 100%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #767b7f;

      background-color: #fff;
      border-radius: 4px;
      padding: 0 10px;
    }
  }
  .links {
    display: flex;
    grid-gap: 20px;
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 38px;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      position: relative;
      min-width: 131px;
      cursor: pointer;
      &.active {
        color: ${(props) => props.theme.colors.branco};
        &:before {
          content: "";
          display: block;
          background-color: ${(props) => props.theme.colors.Laranja1};
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: -21px;
        }
      }
    }
  }
  .imgs {
    display: flex;
    grid-gap: 10px;
    a,
    span {
      background-color: ${(props) => props.theme.colors.Vinho1};
      border-radius: 4px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      /* &:hover {
        background-color: ${(props) => props.theme.colors.Vinho1} !important;
      } */
      &.active {
        background-color: ${(props) => props.theme.colors.Vinho1};
      }
      &.add {
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 0 20px;
        background-color: ${(props) => props.theme.colors.Vinho1} !important;
      }
    }
  }
`;

export const Cadastro = styled.div`
  /* background: linear-gradient(90deg, #0097d5 0%, #005d88 100%); */
  height: 80px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e2e3;
  p {
    font-weight: 700;
    font-size: 25px;
    line-height: 100%;
    display: flex;
    grid-gap: 10px;
    text-transform: uppercase;
    background: none;
    align-items: center;
    cursor: pointer;
    &.voltar {
      font-weight: 500;
      font-size: 14px;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.preto};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .addVoucher {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #00824e;
    }
  }
`;
