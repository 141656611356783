import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { api } from "../../services/api";
import { formatCurrentMoney } from "../../utils/helper";

import Topo from "../../components/topo/cadastro";
import Dados from "../../components/formsNovoPedido/dados";
import Voucher from "../../components/formsNovoPedido/vouchers";
import Box from "../../components/boxProduto/boxView";
import Button from "../../components/button";
import ButtonCancelar from "../../components/ButtonCancelar";
import Loader from "../../components/Loading";

import { Nova } from "./styles";

import logoAprova from "../../assets/img/sistema/logo-aprova.svg";

function NovaAcao() {
  const history = useHistory();
  const [nomeContratante, setNomeContratante] = useState("");
  const [dadosContratante, setDadosContratante] = useState("");
  const [formSelecionada, alteraForm] = useState("dados");

  const [data, setData] = useState({});
  const [telaAprovar, setTelaAprovar] = useState(false);

  const { loading } = useSelector((state) => state.acao);
  const [loadingPedido, setLoadingPedido] = useState(false);

  const tamanhoString = 15;

  const updateData = async (name, values, nextForm = null) => {
    setData({ ...data, [name]: values });

    if (nextForm) {
      alteraForm(nextForm);
    } else {
      console.log("final");
    }
  };

  function submitData(values, produtos) {
    setLoadingPedido(true);
    document.body.style.overflow = "hidden";
    const dados = {
      contratante_nome: values.contratante_nome,
      cliente_area_contato_id: values.cliente_area_contato_id,
      contratante_email: values.contratante_email,
      nome: values.titulo,
      job: values.job,
      entrega_codigo: values.entrega_codigo,
      is_outlet: values.is_outlet,
      variacoes: produtos.map((prod) => {
        return {
          variacao_id: prod.id,
          quantidade: prod.value,
        };
      }),
    };

    if (values.cliente_id !== 9999) {
      dados.cliente_id = values.cliente_id;
    }

    api
      .post(`/pedido`, {
        ...dados,
      })
      .then((data) => {
        document.body.style.overflow = "auto";
        setLoadingPedido(false);
        history.push("/pedidos");
      })
      .catch((error) => {
        setLoadingPedido(false);
        console.log(error);
      });
  }

  function _renderContent() {
    switch (formSelecionada) {
      case "dados": {
        return (
          <Dados
            values={data.dados}
            setNomeContratante={setNomeContratante}
            onSubmit={(values, actions, produtoResult) => {
              if (values.cliente_id === 9999) {
                if (!values.contratante_email) {
                  actions.setFieldError(
                    "contratante_email",
                    "Campo obrigatório"
                  );
                  return;
                }

                if (!values.contratante_nome) {
                  actions.setFieldError(
                    "contratante_nome",
                    "Campo obrigatório"
                  );
                  return;
                }
              }

              setDadosContratante(values);
              updateData("dados", values);
              updateData("variacoes", produtoResult);
              submitData(values, produtoResult);
            }}
          />
        );
      }

      case "vouchers": {
        return (
          <Voucher
            values={data.produtos}
            onSubmit={(values) => {
              updateData("variacoes", values, "aprovar");
            }}
          />
        );
      }

      case "aprovar": {
        setTelaAprovar(true);
        break;
      }
      default: {
        break;
      }
    }
  }

  const qtdVouchers = data?.variacoes?.reduce((acc, produto) => {
    return (acc += produto.value);
  }, 0);

  const valorVouchers = data?.variacoes?.reduce((acc, produto) => {
    return (acc += produto.value * parseInt(produto.valor_reais));
  }, 0);

  return (
    <Nova>
      <Topo />

      {loadingPedido ? (
        <div className="container-loader">
          <Loader />
        </div>
      ) : null}

      {telaAprovar ? (
        <div
          className={`container containerAprovar ${loading ? "active" : ""}`}
        >
          <div className="s-esq">
            <img src={logoAprova} alt="" />
          </div>
          <div className="container-resumo-pedido">
            <h2>Resumo do Pedido</h2>

            <span className="active">
              Cliente
              <small>
                {nomeContratante ? nomeContratante : "Novo Contratante"}
              </small>
              {nomeContratante.length >= tamanhoString ? (
                <p className="toolTip">{nomeContratante}</p>
              ) : null}
            </span>

            <span className="active">
              Nome
              <small>{dadosContratante.contratante_nome}</small>
              {dadosContratante.contratante_nome.length >= tamanhoString ? (
                <p className="toolTip">{dadosContratante.contratante_nome}</p>
              ) : null}
            </span>

            <span className="active">
              Entrega Código
              <small>{dadosContratante.entrega_codigo ? "Sim" : "Não"}</small>
              {dadosContratante.entrega_codigo.length >= tamanhoString ? (
                <p className="toolTip">{dadosContratante.entrega_codigo}</p>
              ) : null}
            </span>

            <span className="active">
              Outlet
              <small>{dadosContratante.is_outlet ? "Sim" : "Não"}</small>
              {dadosContratante.is_outlet.length >= tamanhoString ? (
                <p className="toolTip">{dadosContratante.is_outlet}</p>
              ) : null}
            </span>

            {dadosContratante.contratante_email ? (
              <span className="active">
                E-mail
                <small>{dadosContratante.contratante_email}</small>
                {dadosContratante.contratante_email.length >= tamanhoString ? (
                  <p className="toolTip">
                    {dadosContratante.contratante_email}
                  </p>
                ) : null}
              </span>
            ) : null}

            {dadosContratante.job ? (
              <span className="active">
                E-mail
                <small>{dadosContratante.job}</small>
                {dadosContratante.job.length >= tamanhoString ? (
                  <p className="toolTip">{dadosContratante.job}</p>
                ) : null}
              </span>
            ) : null}

            <span className="active">
              Título do Pedido
              <small>{dadosContratante.titulo}</small>
              {dadosContratante.titulo.length >= tamanhoString ? (
                <p className="toolTip">{dadosContratante.titulo}</p>
              ) : null}
            </span>

            <span className="active">
              Qtd. Vouchers
              <small>{qtdVouchers}</small>
            </span>
            <span className="active">
              Valor
              <small>{formatCurrentMoney(valorVouchers)}</small>
            </span>
          </div>
          <div className="s-dir">
            {data.variacoes.map((produto) => {
              return <Box key={produto.variacao_id} produto={produto} />;
            })}
            <div className="btns">
              <ButtonCancelar pedido={true} />

              <Button title="Aprovar Pedido" onClick={() => submitData()} />
            </div>
          </div>
        </div>
      ) : (
        <div className={`container ${loading ? "active" : ""}`}>
          <h1>Dados do Pedido</h1>
          {_renderContent()}
        </div>
      )}
    </Nova>
  );
}

export default NovaAcao;
