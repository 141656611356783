import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FormInitialState, validationLogin } from "../../utils/configForm";
import { Creators as LoginCreators } from "../../store/ducks/login";

import Button from "../../components/button";

import olho from "../../assets/img/sistema/olho-login.svg";
import logo from "../../assets/img/sistema/logo-login.svg";

function Login(props) {
    const { setEsqueciSenha, esqueciSenha } = props;
    const [type, setType] = useState("password");
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.login);
    const { token } = useSelector((state) => state.user);
    const history = useHistory();

    function toggle() {
        setType(type === "password" ? "text" : "password");
    }

    useEffect(() => {
        // if (token) {
        //     history.push("sistema");
        // }
    }, [token, history]);

    const CPFInput = ({ ...props }) => {
        const [field, meta, helpers] = useField(props);
        const handleCPFChange = (event) => {
            let cpf = event.target.value.replace(/\D/g, '');
            cpf = cpf.substring(0, 11);

            cpf = cpf.replace(/(\d{3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/, (match, p1, p2, p3, p4) => {
                if (p4) {
                    return `${p1}.${p2}.${p3}-${p4}`;
                } else if (p3) {
                    return `${p1}.${p2}.${p3}`;
                } else if (p2) {
                    return `${p1}.${p2}`;
                } else {
                    return p1;
                }
            });
            helpers.setValue(cpf);
        };


        return (
            <input
                {...field}
                {...props}
                onChange={handleCPFChange}
                value={field.value}
            />
        );
    };

    const cleanCPF = (cpf) => cpf.replace(/\D/g, '');

    return (
        <>
            <img src={logo} alt="Logo" className="logo" />
            <span>crie e acompanhe ações desenvolvidas</span>
            <h2>PARA SUA EQUIPE</h2>
            <Formik
                enableReinitialize
                initialValues={FormInitialState.login}
                validationSchema={validationLogin}
                onSubmit={(values, actions) => {
                    const cleanedCPF = cleanCPF(values.cpf);
                    dispatch(LoginCreators.requestLogin({ ...values, cpf: cleanedCPF }, history));
                }}
            >
                {(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <div className="container-form">
                            <CPFInput
                                name="cpf"
                                type="text"
                                placeholder="DIGITE SEU CPF"
                            />                            
                        </div>
                        <ErrorMessage component="small" name="cpf" />
                        <div className="container-form">
                            <Field
                                type={type}
                                placeholder="DIGITE SUA SENHA"
                                name="password"
                                autoComplete="new-password"
                            />
                            <span
                                className={`check-password ${type === "text" ? "" : "active"}`}
                                onClick={toggle}
                            >
                                <img src={olho} alt="Olho" />
                            </span>
                        </div>
                        <ErrorMessage component="small" name="password" />
                        <Button title="ENTRAR" loading={loading} />
                        {/* <small
                            onClick={() => setEsqueciSenha(!esqueciSenha)}
                            className="esqueci-senha"
                        >
                            Esqueci minha senha
                        </small> */}
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default Login;
